import React from 'react';
import { Link, graphql } from 'gatsby';
//import Img from 'gatsby-image'
import Layout from '../components/layout';
import Definitions from '../common/definitions';

const DistrictTemplate = ({ data }) => (
  <Layout>
    <h1>Städte / Orte in {data.strapiDistricts.DistrictName}</h1>
    <div><Link to={Definitions.GalleryPagesBasePath.concat(
      getCountry(data.allStrapiCountries, data.strapiDistricts.state.country).slug.toLowerCase(),'/',
      data.strapiDistricts.state.slug.toLowerCase(),'/',)}
      >zurück</Link></div>
    <ul>
      {data.strapiDistricts.cities.map(
          (city) => {
          return(
            <li key={city.id}><Link to={Definitions.GalleryPagesBasePath.concat(
              getCountry(data.allStrapiCountries, data.strapiDistricts.state.country).slug.toLowerCase(),'/',
              data.strapiDistricts.state.slug.toLowerCase(),'/',
              data.strapiDistricts.slug.toLowerCase(),'/',
              city.slug.toLowerCase(),'/')}
              >{city.CityName}</Link></li>
          );
        })
      }
    </ul>
  </Layout>
);

const getCountry = (countryList, countryId) => {
  for(let i = 0; i < countryList.edges.length; i++){
    if (countryList.edges[i].node.strapiId === countryId) {
      return countryList.edges[i].node;
    }
  }
  return null;
}

export default DistrictTemplate;

export const query = graphql`
  query DistrictTemplate($id: String!) {
    strapiDistricts(id: {eq: $id}) {
      DistrictName
      strapiId
      id
      slug
      state {
        StateName
        slug
        id
        country
      }
      cities {
        CityName
        slug
        id
      }
    }
    allStrapiCountries {
      edges {
        node {
          id
          strapiId
          CountryName
          slug
        }
      }
    }
    
  }
`;